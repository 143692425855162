import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@emotion/react';
import { ColorModeContext, tokens } from '../../pages/scenes-dash/theme';

const avatar = require('../../assets/images/J_Crew_Fall_2016_Ready-to-Wear_Fashion_Show_rbbkgu.jpg')
export default function About() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const colorMode = useContext(ColorModeContext)
    const {about} = useSelector(state=> state.about)
  return (
    <section className="about " id="about">
            <div className="container">
                <h2 className="section-title padd-15" style={{
                        color:theme.palette.mode === 'dark'?'white':'black',
                    }}>About Me</h2>
                <div className="row">
                    <div className="about-img padd-15">
                    <Avatar
                        alt="Remy Sharp"
                        src={about?.image?about.image:avatar}
                        sx={{ 
                            width: '150px', 
                            height: '150px',
                            marginBottom:'20px !important' ,
                            margin:'auto',
                            
                            borderRadius:'50%',
                            backgroundPosition:'cover'
                        }}
                    />
                    </div>
                    <div  className="about-content padd-15">
                        <div style={{backgroundColor:colors.primary}} className="rounded">
                           <div className="row">
                            <div className="about-text padd-15">
                                
                                <p>I am Dennis, web developer from Nairobi, Kenya. I have rich experience in web design and web api integration...Am also good at making advanced php apps</p>
                                <div>
                                    <a href="#" className="btn btn-default">Download CV</a>
                                </div>
                            </div>
                            <div className="skills padd-15">
                                <div className="row">
                                    <div className="skill-item">
                                        <h4 style={{
                        color:theme.palette.mode === 'dark'?'white':'black'
                    }}>Php</h4>
                                        <div className="progress">
                                            <div className="progress-in"
                                            style={{width: '95%', background: 'rgb(255, 209, 92)'}}></div>
                                            <div className="skill-percent">95%</div>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <h4 style={{
                        color:theme.palette.mode === 'dark'?'white':'black'
                    }}>javascript</h4>
                                        <div className="progress">
                                            <div className="progress-in"
                                            style={{width: '75%', background: 'rgb(255, 76, 96)'}}></div>
                                            <div className="skill-percent">75%</div>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <h4 style={{
                        color:theme.palette.mode === 'dark'?'white':'black'
                    }}>React Js</h4>
                                        <div className="progress">
                                            <div className="progress-in"
                                            style={{width: '75%', background: 'rgb(24, 184, 184)'}}></div>
                                            <div className="skill-percent">75%</div>
                                        </div>
                                    </div>
                                    <div  className="skill-item">
                                        <h4 style={{
                        color:theme.palette.mode === 'dark'?'white':'black'
                    }}>Redux</h4>
                                        <div className="progress">
                                            <div className="progress-in" 
                                            style={{width: '65%', background: 'rgb(42, 106, 158)'}}></div>
                                            <div className="skill-percent">65%</div>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <h4 style={{
                        color:theme.palette.mode === 'dark'?'white':'black'
                    }}>Fast Api</h4>
                                        <div className="progress">
                                            <div className="progress-in"
                                            style={{width: '25%', background: 'rgb(219, 96, 14)'}}
                                          ></div>
                                            <div className="skill-percent">25%</div>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <h4 style={{
                        color:theme.palette.mode === 'dark'?'white':'black'
                    }}>Express Api</h4>
                                        <div className="progress">
                                            <div className="progress-in"
                                            style={{width: '85%', background: 'rgb(86, 129, 30)'}}></div>
                                            <div className="skill-percent">85%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           </div> 
                           
                        </div>
                        
                    </div>
                </div>
                <div class="row">
                    <div class="fact-item padd-15">
                       <i class="lni lni-thumbs-up"></i>
                       <div class="details">
                        <h3 style={{
                        color:theme.palette.mode === 'dark'?'white':'black'
                    }}>198</h3>
                        <p>Projects done</p>
                       </div>
                    </div>
                    <div class="fact-item padd-15">
                        <i class="lni lni-coffee-cup"></i>
                        <div class="details">
                         <h3 style={{
                        color:theme.palette.mode === 'dark'?'white':'black'
                    }}>6798</h3>
                         <p>Cup of Coffee</p>
                        </div>
                     </div>
                    <div class="fact-item padd-15">
                        <i class="lni lni-users"></i>
                        <div class="details">
                         <h3 style={{
                        color:theme.palette.mode === 'dark'?'white':'black'
                    }}>428</h3>
                         <p>Satisfied clients</p>
                        </div>
                     </div>
       
                    <div class="fact-item padd-15">
                        <i class="lni lni-cup"></i>
                        <div class="details">
                         <h3 style={{
                        color:theme.palette.mode === 'dark'?'white':'black'
                    }}>28</h3>
                         <p>Code Challenges</p>
                        </div>
                     </div>
               </div>
            </div>
        </section>
  )
}
