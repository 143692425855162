import React, { useEffect, useState } from 'react';
import './style.css';
import {CircleLoader} from 'react-spinners';
import MainContext from '../../../contexts/MainContext';
import {Add, ArrowCircleLeftOutlined, ArrowCircleRightOutlined, ArrowRightAltRounded } from '@mui/icons-material';
import { addToken } from '../../../redux-store/auth/AuthSlice';
import { useDispatch } from 'react-redux';
import { Box, ChevronBarRight, Eye, EyeSlash } from 'react-bootstrap-icons';

export default function TierBox() {
    const [emailSet, setEmailSet] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null); 
    const {checkEmail, loading, emailRes, addTier } = MainContext();
    const [name, setTierName] = useState('');
    const [amount, setAmount] = useState('');
    const [add, setAdd] = useState(false);
    const [see, setSee] = useState(false);
   
    const dispatch = useDispatch();
    

    const addT = async ()=>{
        const res = await addTier({name, amount});
        if(res.error){
            setError(res.error);
            setSuccess(null)
        }else{
            setError(null)
            setSuccess(res.success);
        }
        console.log(res);
    }
   
    
  return (
    <div className="form">
       
                <div>
                    <div className="namesinput">
                        <label className='labelText'>Name</label>      
                        <input type='text' value={name}  onChange={(e)=>{setTierName(e.target.value)}} placeholder='Tier' className='input-account'/>
                    </div>
                </div>
                <div>
                    <div className="namesinput">
                        <label className='labelText'>Amount</label>      
                        <input type='number' value={amount}  onChange={(e)=>{setAmount(e.target.value)}} placeholder='0.0' className='input-account'/>
                    </div>
                </div>
         
        {
                        error&&(
                            <small style={{color:'crimson',marginTop:20, display:'flex',alignItems:'center', justifyContent:'center', fontWeight:'bold'}}>{error}</small>
                        )
                    }
                    {
                        success&&(
                            <small style={{color:'#0077b5',marginTop:20, display:'flex',alignItems:'center', justifyContent:'center', fontWeight:'bold'}}>{success}</small>
                        )
                    }
        
                <button 
                    className='btnLogin'
                    onClick={addT}
                    style={{
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        borderRadius:10,
                        marginTop:40
                    }}
                >
            {
                loading?(
                    <CircleLoader color='white' size={20}/>
                ):(
                    <h6 style={{display:'flex',
                    alignItems:'center',
                    justifyContent:'center', letterSpacing:.4, marginTop:4}}>Add &nbsp;<Add /></h6>
                )
            }
            
        </button>
         
        

    </div>
  )
}
