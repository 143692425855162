import React, { useEffect, useState } from 'react';
import './style.css';
import {PuffLoader } from 'react-spinners';
import MainContext from '../../../contexts/MainContext';
import {ArrowCircleLeftOutlined, ArrowCircleRightOutlined, ArrowCircleRightRounded, ArrowDownwardOutlined, ArrowRight, ArrowRightAltRounded, ChevronRightOutlined } from '@mui/icons-material';
import { addToken } from '../../../redux-store/auth/AuthSlice';
import { useDispatch } from 'react-redux';
import { ChevronBarDown, ChevronBarRight, Eye, EyeSlash } from 'react-bootstrap-icons';
import Box from '@mui/material/Box';

export default function Prem() {
    const [emailSet, setEmailSet] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const [error, setError] = useState(null);
    const {loading, getTier, getTiers, verifyPremium } = MainContext();
    const [email, setEmail] = useState('');
    const [password, setPsd] = useState('');
    const [payPage, setPayPage] = useState(false);
    const [see, setSee] = useState(false);
    const [tiers, setTiers] = useState([]);
    const [tier, setTier] = useState(null)
    const [selectedTier, setSelectedTier] = useState('')
   
    const dispatch = useDispatch();

    const verify = async ()=>{
        const res = await verifyPremium()
        
    }
    const getPrem = async ()=>{
        const res = await getTiers();
        setTiers(res.tiers)
    }
    const getDetailTier = async (id)=>{
        const res = await getTier(id);
        if(!res.error){
            setPayPage(true);
            setError(null);
            setTier(res.tier)
        }else{
            setPayPage(false);
            setError(res.error)
        }
    }
    const handleChange = async (e)=>{
        setSelectedTier(e.target.value)
        if(e.target.value !== ''){
            getDetailTier(e.target.value)   
        }
    }
    useEffect( ()=>{
        verify();
        getPrem()
    },[])
    
  return (
    <div >
       
       {(loading )?
       (<PuffLoader color='black' size={20}/>):
       (
            <>
                {
                    payPage?(
                        <div >
                             <button 
                                    onClick={()=>{setPayPage(false)}}
                                    style={{
                                        marginBottom:5
                                    }}
                                >
                                    
                                    <h6 style={{display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center', letterSpacing:.4, marginTop:4}}><ArrowCircleLeftOutlined /> </h6>
                        
                                </button>
                                <div
                                    style={{
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent:'center',
                                        flexDirection:'column'
                                    }}
                                >
                                    
                                    <div
                                        style={{
                                            display:'flex',
                                            alignItems:'center',
                                            justifyContent:'center',
                                            flexDirection:'row'
                                        }}
                                    >
                                        <div>
                                            <h2 style={{
                                                fontSize:20,
                                                color:'grey'
                                            }}>$</h2>
                                        </div>
                                       <div>
                                            <span style={{fontSize:50, color:'#002de4', marginTop:20, marginLeft:4}}>{tier.amount}</span>
                                       </div>
                                    </div>

                                    <div>
                                        <button
                                            style={{
                                                width:60,
                                                height:40,
                                                backgroundColor:'#4b59a4',
                                                borderRadius:5,
                                                padding:10,
                                                color:'white'
                                            }}
                                        >
                                           <p style={{textAlign:'center', color:'white'}}> Pay </p>
                                        </button>
                                    </div>
                                </div>
                        </div>
                        
                    ):(
                        <div className="form">
                            <div className="namesinput">    
          
                                <select className='select-account' value={selectedTier} onChange={(e)=>{handleChange(e)}}>
                                    <option value='' className='optionAccount' >Choose Tier</option>     
                                    {
                                        tiers.map((tier)=>(  
                                                <option key={tier._id} className='optionAccount' value={tier._id}>{tier.name}</option>
                                        ))
                                    }    
                                </select>    
                            </div>
                        </div>
                        
                    )
                }
            </>
       )
       }
         
        {
                        error&&(
                            <small style={{color:'crimson',marginTop:20, display:'flex',alignItems:'center', justifyContent:'center', fontWeight:'bold'}}>{error}</small>
                        )
                    }
        
         
         
        

    </div>
  )
}
