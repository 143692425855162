import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import appImg from '../../assets/images/pexels4.jpg'
import TopBar from '../../pages/scenes-dash/global/TopBar';
import { ColorModeContext, tokens } from '../../pages/scenes-dash/theme';

import '../../pages/home.css'
import Account from '../../pages/modal/Account';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutline'
import { Avatar, ListItemAvatar } from '@mui/material';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function Header() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const colors = tokens(theme.palette.mode)
  const colorMode = React.useContext(ColorModeContext)
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{
        backgroundColor:theme.palette.mode === 'dark' ? colors.primary[400]:'white'
      }} open={open}>
        <Box display='flex' p={1} justifyContent='space-between'>
            
          <Box 
            p={1}
            mt='8px'
          >
            <IconButton
                color={theme.palette.mode === 'dark' ? 'white':colors.primary[400]}
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
                <MenuIcon />
            </IconButton>
          </Box>
          <Box>
          <TopBar/>
          
          </Box>
          
        </Box>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: theme.palette.mode === 'dark' ? colors.primary[400]:'white'
          }
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader
            sx={{
                display:'flex',
                alignItems:'center',
                justifyContent:'space-between'
            }}
        >
            <Box
                display='flex'
            >
            <Typography color={theme.palette.mode === 'dark' ? 'white':'black'}>
                Teeti
            </Typography>
            <Typography color='#e64d61' >
                Colab
            </Typography>
            </Box>
            <Box>
                <IconButton color='inherit' onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            </Box>
          
        </DrawerHeader>
        <Divider />
        <List>
          
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                
                </ListItemIcon>
                <Account Icon={PersonOutlinedIcon}/>
              </ListItemButton>
            </ListItem>
        
        </List>
        <Divider />
        
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div
          style={{
            marginTop:20
          }}
        >
        <ListItem  alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Travis Howard" src={appImg} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
                <Typography
                    color={theme.palette.mode === 'dark'?'white':'black'}
                    fontWeight={800}
                >
                    ReactNative Auth Prototype
                </Typography>
                
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color='grey'
              >
                This is a simple prototype
              </Typography>
              <a href={require('../../assets/proto.apk')} style={{
                  margin:10
                }} className='btn' download>Download</a>
             </React.Fragment>
            
          }
          
        />
        
      </ListItem>
        </div>
      </Main>
    </Box>
  );
}