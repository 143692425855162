import React,{memo,useEffect, useState} from 'react'
import { getUser } from '../redux-store/auth/UserSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getAbout, getAllabouts, getPing } from '../redux-store/features/AboutSlice'
import { BarLoader, DotLoader } from 'react-spinners'
import { addPremium, logout } from '../redux-store/auth/AuthSlice'
import { Avatar } from '@mui/material'
import { deepOrange } from '@mui/material/colors'
import { addToken } from '../redux-store/auth/AuthSlice'
import { ColorModeContext, useMode } from './scenes-dash/theme'
import {CssBaseline, ThemeProvider} from '@mui/material'
import { Link, useHref, useNavigate} from 'react-router-dom'
import Header from '../components-dash/header'
import Accordion from '../components-dash/accordion/Accordion'
import './dashboard.css'
import Action from '../components-dash/action'
import MainContext from '../contexts/MainContext'


function Content(){
  return(
    <div
      className='contentBox'
    >
      <div>
        profile
      </div>
      <div>
        other
      </div>
    </div>
  )
}
function Title({title}){
  return(
    <div>
      <h4 style={{color:'white'}}>{title}</h4>
    </div>
    
  )
}
function Dash({}) {
  const dispatch = useDispatch()
  const [theme, colorMode] = useMode()
  const {user,errorUser,loadingUser} = useSelector(state=>state.user)
  const {about, error, loading} = useSelector(state=>state.about)
  const {verifyPremium} = MainContext()
  const href = useHref()
  const navigate = useNavigate();
  const {token, premium} = useSelector(state=>state.auth)
  const verify = async ()=>{
    await verifyPremium();
  }
  useEffect(()=>{
    verify()
    dispatch(addToken())
    dispatch(getUser())
    dispatch(getAllabouts())
    dispatch(addPremium())
  },[])
  
  useEffect(()=>{
    if(!token){
      navigate('/')
    }else{
      navigate('/dashboard')
    }
  },[token])
  useEffect(()=>{
    dispatch(getAbout(user?.userid))
  },[user])

 
  
  return (
    <>
    {
      token?(
        <>
        <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
          <Header/>
          <main className="main-content">
            <div className='' style={{marginTop:15}}>
            
                <Action/>
              
              
            </div>
            
            
          </main>
          </ThemeProvider>
      
      </ColorModeContext.Provider>
        </>
      ):(
        <a href='/'>Log in</a>
      )
    }
          
    </>
  )
}

export default memo(Dash)