import React from 'react'

import DangerousHTML from 'dangerous-html/react'

import MenuOutlined from '@mui/icons-material/MenuOutlined'
import ActiveUsers from '../../components-vendor/active-users'
import YouTube from '../../components-vendor/you-tube'
import Testimonal from '../../components-vendor/testimonal'
import './home.css'
import Account from '../modal/Account'
import { IconButton } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { addToken } from '../../redux-store/auth/AuthSlice'
import { useEffect } from 'react'

function Elem (){
  return(
    <span className="home-sign-in Link">Sign in</span>
  )
}
function ElemGetStarted(){
  return(
    <div className="home-get-started" >
      <span className="home-text006">Get started</span>
    </div>
  )
}
function ElemGetStarted2(){
  return(
    <div className="home-get-started1" style={{backgroundColor:'white', color:'black'}}>
      <span className="home-text009">Get started</span>
    </div>
  )
}
function ElemGetStarted3(){
  return(
    <div className="home-get-started2 template-button">
                <span className="home-text030">Get started</span>
              </div>
  )
}
const Home = (props) => {
  const {token} = useSelector(state=>state.auth);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(addToken())
  },[])
  return (
    <div className="home-container">
     
      <section className="home-hero">
        <div className="home-menu">
          <div id="mobile-menu" className="home-mobile-navigation">
            <div className='home-left'>
            <h3 className="home-logo" style={{color:'black', marginTop:10}}>
              Colab
            </h3>
            <div className="home-links">
              <a href='#working' className="Link">How it works</a>
              <a href='#pricing' className="Link">Prices</a>
            </div>
            </div>
            <div className="home-right">
              {
                token?(
                  <a href='/dashboard' className="home-sign-in Link">Dashboard</a>
                ):(
                  <>
                    <Account Element={Elem}/>
                
                
                <Account Element={ElemGetStarted}/>
                  </>
                )
              }
                
                
                
            </div>
            
          </div>
          <div className="home-desktop-navigation">
            <nav className="home-centered">
              <div className="home-left">
              <h6 className="home-logo1" style={{color:'black', fontWeight:'800', marginTop:10}}>
                Colab
              </h6>
                
                <div className="home-links1">
                  <a href='#working' className="Link">How it works</a>
                  <a href='#pricing' className="Link">Prices</a>
                </div>
              </div>
              <div className="home-right">
              {
                token?(
                  <a href='/dashboard' className="home-sign-in Link">Dashboard</a>
                ):(
                  <>
                    <Account Element={Elem}/>
                
                
                <Account Element={ElemGetStarted}/>
                  </>
                )
              }
                
              </div>
            </nav>
          </div>
          <div>
            <DangerousHTML
              html={`<script>
/*
Mobile menu - Code Embed
*/

</script>`}
            ></DangerousHTML>
          </div>
        </div>
        <header className="home-header">
          <h1 className="home-text007">Create Your Own Online Space</h1>
          <p className="home-text008">
            Use Teeti Colab to advertise your skills and products using our custom website template.
          </p>
          {
                token?(
                  <a  href='#sales' className="home-get-started1" style={{backgroundColor:'white', color:'black'}}>
                    <span className="home-text009">Continue</span>
                  </a>
                  
                ):(
                  <>
                    <Account Element={ElemGetStarted2}/>
                  </>
                )
              }
          
          
        </header>
        
      </section>
      
      <section className="home-quote-container">
        <div className="home-quote">
          <span className="home-message">
            “We give you quality service at a lower price. Let's get your business to the next level”
          </span>
          <div className="home-author">
            <img
              alt="image"
              src={require('../../assets/images/founder.JPG')}
              className="home-avatar"
            />
            <span className="home-quote1">
              <span className="home-text025" style={{color:'#4b59a4'}}>—  Dennis Kateeti</span>
              <span style={{color:'#4b59a4'}}>, Founder and CEO, TeetiColab</span>
            </span>
          </div>
        </div>
      </section>
      
      <section className="home-banners" id='sales'>
        
          
        
        <div className="home-banner">
          <div className="home-container5">
            <div className="home-left2">
              <span style={{color:'white'}} className="home-text039">Improve Your Sales</span>
              <h2 style={{color:'white'}} className="home-text040 title">
                We give you a space to reach customers online.
              </h2>
              <span style={{color:'white'}} className="home-text041">
                You can either use our custom template or request a custom build from our developers.
              </span>
              {
                token?(
                  <a href='/dashboard' className="home-get-started2 template-button">
                    <span className="home-text030">Dashboard</span>
                  </a>
                ):(
                  <Account Element={ElemGetStarted3}/>
                )
              }
              
            </div>
            <div className="home-image-container2">
              <img
                alt="pastedImage"
                src={require("../../assets/playground_assets/83d913a38a75e38d7cef4fea757ed7ed-1200w.png")}
                className="home-cards-image2"
              />
            </div>
          </div>
        </div>
      </section>
     
      <section id='working' className="home-how-it-works">
        <div className="home-centered-container2">
          <div className="home-heading1" style={{}}>
            <span className="home-text046" style={{color:'grey'}}>How it works</span>
            
          </div>
          {
            !token&&(
              <div className="home-category2">
            <div className="home-headng">
              <span className="home-text048">
                1 — Sign up
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text049">
                First you need to create an account with us. After creating an account you can create a free portfolio space or purchase an online store space.
              </span>
              <Account Element={ElemGetStarted3}/>
            </div>
            <div className="home-container8">
              <img
                alt="pastedImage"
                src={require("../../assets/playground_assets/pastedimage-k5xi 1-1200w.png")}
                className="home-pasted-image"
              />
            </div>
          </div>
            )
          }
          <div className="home-row">
            <div className="home-category3">
              <div className="home-headng1">
                {token?(<span className="home-text051">1 — Create and Customize Store</span>):(<span className="home-text051">2 — Create and Customize Store</span>)}
                <span className="home-text052">
                  We give you the ability to change our default template to suit your store.
                </span>
              </div>
              <img
                alt="pastedImage"
                src={require("../../assets/playground_assets/pastedimage-ibg-1200w.png")}
                className="home-pasted-image1"
              />
            </div>
            <div className="home-category4">
              <div className="home-headng2">
                {token?(<span className="home-text053">2 — Customize Portfolio</span>):(<span className="home-text053">3 — Customize Portfolio</span>)}
                <span className="home-text054">
                  Every user has a default portfolio template which is free, advertise your skills and use our portfolio to get that job.
                </span>
              </div>
              <img
                alt="pastedImage"
                src={require("../../assets/playground_assets/pastedimage-k5xi 1-1200w.png")}
                className="home-pasted-image2"
              />
            </div>
          </div>
        </div>
      </section>
      <section id='pricing' className="home-pricing">
        <div className="home-centered-container3">
          <div className="home-heading2">
            <span className="home-text055 title">
              Pricing for all kind of businesses
            </span>
            <span className="home-text056">
              Create next-generation solutions for small businesses with
              pricing options that accommodate everyone.
            </span>
            
          </div>
          <div className="home-cards1">
            <div className="home-card">
              <span className="home-text059">Free</span>
              <span className="home-text060">
                Create a portfolio and advertise your skills professionally. Add your experience and projects you've worked on.
              </span>
              {
                token&&(
                  <div className="home-get-started5 template-button">
                    <span className="home-text061">Start for free</span>
                  </div>
                )
              }
              <span className="home-text062">What&apos;s included</span>
              <div className="home-bullet-points">
                <div className="home-point">
                <CheckCircle fontSize='15' style={{color:'#26b999'}} />
                  <span className="home-text063">
                    Customazible portfolio template
                  </span>
                </div>
                <div className="home-point01">
                <CheckCircle fontSize='15' style={{color:'#26b999'}} />
                  <span className="home-text064">
                    24/7 support
                  </span>
                </div>
              </div>
            </div>
            <div className="home-card1">
              <span className="home-text065">Pay as you go</span>
              <span className="home-text066">
                <span>Launch your site for $3/mo</span>
                <br></br>
                <span>Advertise your store using our default template.</span>
              </span>
              {
                token&&(
                  <div className="home-get-started6 template-button">
                <span className="home-text070">
                  <span>Upgrade now</span>
                  <br></br>
                </span>
              </div>
                )
              }
              <span className="home-text073">What&apos;s included</span>
              <div className="home-bullet-points1">
                
                <div className="home-point03">
                <CheckCircle fontSize='15' style={{color:'#26b999'}} />
                  <span className="home-text075">
                    Customazible online store template
                  </span>
                </div>
                <div className="home-point04">
                <CheckCircle fontSize='15' style={{color:'#26b999'}} />
                  <span className="home-text076">
                  Create multiple sites
                  </span>
                </div>
                <div className="home-point05">
                <CheckCircle fontSize='15' style={{color:'#26b999'}} />
                  <span className="home-text077">
                  24/7 support
                  </span>
                </div>
              </div>
            </div>
            <div className="home-card2" >
              <span className="home-text078">Enterprise</span>
              <span className="home-text079">
                <span>
                  Quality custom-built sites from our developers. We take your imagination and turn it to reality.
                </span>
                <br></br>
              </span>
              {
                token&&(
                  <div style={{backgroundColor:'white'}} className="home-get-started7 template-button">
                <span className="home-text082">
                  <span style={{color:'black'}}>Contact us</span>
                  <br></br>
                </span>
              </div>
                )
              }
              <span className="home-text085">What&apos;s included</span>
              <div className="home-bullet-points2">
                <div className="home-point06">
                <CheckCircle fontSize='15' style={{color:'#26b999'}} />
                  <span className="home-text086">
                    Fully custom site of your choosing
                  </span>
                </div>
                <div className="home-point07">
                <CheckCircle fontSize='15' style={{color:'#26b999'}} />
                  <span className="home-text087">
                    Discounted price
                  </span>
                </div>
                
                <div className="home-point09">
                <CheckCircle fontSize='15' style={{color:'#26b999'}} />
                  <span className="home-text089">
                  24/7 support
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {
        !token&&(
          <section className="home-action-bar">
        <div className="home-action">
          <div className="home-heading3">
            <span className="home-text095 title">
              <span>Purchase 🙂</span>
              
            </span>
            <span className="home-text099">
              Login to purchase above packages and enjoy unlimited access to teeticolab.
            </span>
            <Account Element={ElemGetStarted3}/>
          </div>
          <div className="home-images">
            <img
              alt="image"
              src={require("../../assets/playground_assets/e564eaa3c4fab71792794d666a281742-1200w.png")}
              className="home-image1"
            />
          </div>
        </div>
      </section>
        )
      }
      <section>
        <div
          style={{
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            marginTop:20,
            marginBottom:30
          }}
        >
          <span style={{color:'lightgrey'}}>Teeticolab @2023</span>
        </div>
      </section>
    
    
    </div>
  )
}

export default Home
