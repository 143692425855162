import React,{useEffect,useState, Suspense} from 'react';
import {BrowserRouter, Route, Routes, useParams} from 'react-router-dom'
import { addToken } from './redux-store/auth/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from './redux-store/auth/UserSlice';
import Dash from './pages/Dash'
import Form from './pages/scenes-dash/form'
import { ColorModeContext,useMode } from './pages/scenes-dash/theme';
import {CssBaseline, ThemeProvider} from '@mui/material'
import TopBar from './pages/scenes-dash/global/TopBar';
import Geography from './pages/scenes-dash/geography';
import SideBar from './pages/scenes-dash/global/SideBar';
import Home from './pages/Home';
import Vendor from './pages/Vendor'
import './pages/dash.css';
import 'react-bootstrap/dist/react-bootstrap'
import AppPrototype from './pages/AppPrototype';

function BaseComponent({token, isDark,setIsDark}){
  
  return(
    <>
    {
      token?
      (
        <Dash isDark={isDark} setIsDark={setIsDark}/>
      )
      :(
        <Vendor isDark={isDark} setIsDark={setIsDark}/>
      )
    }
    </>
  )
}
function App() {
  const dispatch = useDispatch()
  const {token} = useSelector(state=>state.auth)
  const [isDark, setIsDark] = useState(true)
  const [theme, colorMode] = useMode()
  const {userId} = useParams()
  useEffect(()=>{
    dispatch(addToken())
    
  },[])
  useEffect(()=>{
    console.log(userId)
  },[userId])
  return (
    <>
        
        <div className='app'>
          <main className='content'>
   
          
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Vendor/>} />
              <Route path='/dashboard' element={<Dash/>} />
              <Route exact path='/vendor/:userId' element={<Home/>} />
              <Route exact path={`/prototypes`} element={<AppPrototype/>} />
              <Route exact path="/form" element={<Form/>}/>
              {
                token ?(
                  <>
                    <Route path="/geo" element={<Geography/>}/>
                    
                  </>
                  
                  ):(
                  <></>
                )
              }
              
            </Routes>
          </BrowserRouter>
          </main>
        </div>
      
        
        
    </>
    
  );
}

export default App;
