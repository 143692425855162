import { useTheme } from '@emotion/react';
import { IconButton } from '@mui/material';
import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ColorModeContext, tokens } from '../scenes-dash/theme';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Login from './components/Login';
import Register from './components/Register';
import './components/style.css';
import { flexibleCompare } from '@fullcalendar/core/internal';


function MyVerticallyCenteredModal(props) {
  const [showPassword, setShowPassword] = React.useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const colorMode = useContext(ColorModeContext)
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [isLogin, setIsLogin] = useState('Login')
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Modal
      {...props}
     centered
     size='sm'
      
    >
      <Modal.Header
      closeButton>
        <Modal.Title 
        >
          
              {
                isLogin === 'Login' && (
                  <h4>Login  &nbsp; &nbsp;</h4>
                )
              }
              {
                isLogin === 'Register' && (
                  <h4>SignUp  &nbsp; &nbsp;</h4>
                )
              }
           
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          isLogin==='Login'&& (
            <Login />
          )
        }
        {
          isLogin==='Register'&& (
            <Register setIsLogin={setIsLogin} />
          )
        }
      </Modal.Body>
      <Modal.Footer>
        {
          isLogin === 'Login'&&(
           <div
            style={{
              display:'flex',
              alignItems:'center',
              justifyContent:'space-between',
              flexDirection:'row'
            }}
           >
             <div>
             <p style={{color:'silver', fontSize:12}}>Dont Have An Account? </p>
             </div>
             <div>
             <button onClick={()=>{setIsLogin('Register')}} style={{color:'blue', fontSize:12, marginBottom:16, marginLeft:7}}>Register</button>
             </div>
           </div>
          )
        }
         {
          isLogin === 'Register'&&(
            <div
            style={{
              display:'flex',
              alignItems:'center',
              justifyContent:'space-between',
              flexDirection:'row'
            }}
           >
             <div>
             <p style={{color:'silver', fontSize:12}}>Aleady Have An Account? </p>
             </div>
             <div>
             <button onClick={()=>{setIsLogin('Login')}} style={{color:'blue', fontSize:12, marginBottom:16, marginLeft:7}}>Login</button>
             </div>
           </div>
          )
        } 
       
      </Modal.Footer>
    </Modal>
  );
}

function Account({Icon, Btn, Element}) {
  const [modalShow, setModalShow] = useState(false);
  const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const colorMode = useContext(ColorModeContext)
    

  return (
    <>
      {
        Icon && (
          <IconButton color={theme.palette.mode === 'dark' ? 'white':colors.primary[400]} onClick={() => setModalShow(true)}>
        <Icon/>
      </IconButton>
        )
      }
      {
        Btn === true && (
          <button 
            className='btn btn-primary'
            onClick={() => setModalShow(true)}
          >
            Account
        </button>
        )
      }
      {
        Element &&(
          <Box onClick={()=>setModalShow(true)}>
            <Element />
          </Box>
        )
      }

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        
      />
    </>
  );
}

export default Account