import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ColorModeContext, tokens } from '../../pages/scenes-dash/theme';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function TabsPortfolio() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const colors = tokens(theme.palette.mode)
  const colorMode = React.useContext(ColorModeContext)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: theme.palette.mode === 'dark' ? colors.primary[400]: 'lightgray' }}>
      <AppBar sx={{ bgcolor: theme.palette.mode === 'dark' ? colors.primary[400]: 'white' }}  position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          aria-label="full width tabs example"
        >
          <Tab sx={{ml:7, color: theme.palette.mode === 'dark' ? 'white': 'black'}} label="Item One" {...a11yProps(0)} />
          <Tab sx={{ml:27, color: theme.palette.mode === 'dark' ? 'white': 'black'}} label="Item Two" {...a11yProps(1)} />
          <Tab sx={{ml:27, color: theme.palette.mode === 'dark' ? 'white': 'black'}} label="Item Three" {...a11yProps(2)} />
          
          <Tab sx={{ml:27, color: theme.palette.mode === 'dark' ? 'white': 'black'}} label="Item Three" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
     
        <TabPanel value={value} index={0} dir={theme.direction}>
          <div class="row">
                    
                    <div class="portfolio-item padd-15" data-category="php">
                        <div class="portfolio-item-inner">
                            <div class="portfolio-item-thumbnail">
                           <img style={{width:'100%', height:'100%'}} src={require('../../assets/images/J_Crew_Fall_2016_Ready-to-Wear_Fashion_Show_rbbkgu.jpg')} alt=""/>
                                <div class="mask"></div>
                            </div>
                            <span class="term">Art, design</span>
                            <h3 class="portfolio-item-title">Project management Illustration</h3>
                            <span class="more-button">&hellip;</span>
                        </div>
                    </div>
                   
                    <div class="portfolio-item padd-15" data-category="php">
                        <div class="portfolio-item-inner">
                            <div class="portfolio-item-thumbnail">
                           <img style={{width:'100%', height:'100%'}} src={require('../../assets/images/J_Crew_Fall_2016_Ready-to-Wear_Fashion_Show_rbbkgu.jpg')} alt=""/>
                                <div class="mask"></div>
                            </div>
                            <span class="term">Art, design</span>
                            <h3 class="portfolio-item-title">Project management Illustration</h3>
                            <span class="more-button">&hellip;</span>
                        </div>
                    </div>
                   
                    <div class="portfolio-item padd-15" data-category="php">
                        <div class="portfolio-item-inner">
                            <div class="portfolio-item-thumbnail">
                           <img style={{width:'100%', height:'100%'}} src={require('../../assets/images/J_Crew_Fall_2016_Ready-to-Wear_Fashion_Show_rbbkgu.jpg')} alt=""/>
                                <div class="mask"></div>
                            </div>
                            <span class="term">Art, design</span>
                            <h3 class="portfolio-item-title">Project management Illustration</h3>
                            <span class="more-button">&hellip;</span>
                        </div>
                    </div>
           
                    <div class="portfolio-item padd-15" data-category="php">
                        <div class="portfolio-item-inner">
                            <div class="portfolio-item-thumbnail">
                           <img style={{width:'100%', height:'100%'}} src={require('../../assets/images/J_Crew_Fall_2016_Ready-to-Wear_Fashion_Show_rbbkgu.jpg')} alt=""/>
                                <div class="mask"></div>
                            </div>
                            <span class="term">Art, design</span>
                            <h3 class="portfolio-item-title">Project management Illustration</h3>
                            <span class="more-button">&hellip;</span>
                        </div>
                    </div>
                    <div class="portfolio-item padd-15" data-category="apps">
                        <div class="portfolio-item-inner">
                            <div class="portfolio-item-thumbnail">
                                {/*<img src="assets/img/6.svg" alt="">*/}
                                <div class="mask"></div>
                            </div>
                            <span class="term">App design</span>
                            <h3 class="portfolio-item-title">modern app designs using flutter</h3>
                            <span class="more-button">&hellip;</span>
                        </div>
                    </div>
                </div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <div className='row'>
          <div class="portfolio-item padd-15" data-category="php">
                        <div class="portfolio-item-inner">
                            <div class="portfolio-item-thumbnail">
                           <img style={{width:'100%', height:'100%'}} src={require('../../assets/images/J_Crew_Fall_2016_Ready-to-Wear_Fashion_Show_rbbkgu.jpg')} alt=""/>
                                <div class="mask"></div>
                            </div>
                            <span class="term">Art, design</span>
                            <h3 class="portfolio-item-title">Project management Illustration</h3>
                            <span class="more-button">&hellip;</span>
                        </div>
                    </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <div className="row">
          <div class="portfolio-item padd-15" data-category="php">
                        <div class="portfolio-item-inner">
                            <div class="portfolio-item-thumbnail">
                           <img style={{width:'100%', height:'100%'}} src={require('../../assets/images/J_Crew_Fall_2016_Ready-to-Wear_Fashion_Show_rbbkgu.jpg')} alt=""/>
                                <div class="mask"></div>
                            </div>
                            <span class="term">Art, design</span>
                            <h3 class="portfolio-item-title">Project management Illustration</h3>
                            <span class="more-button">&hellip;</span>
                        </div>
                    </div>
                    <div class="portfolio-item padd-15" data-category="php">
                        <div class="portfolio-item-inner">
                            <div class="portfolio-item-thumbnail">
                           <img style={{width:'100%', height:'100%'}} src={require('../../assets/images/J_Crew_Fall_2016_Ready-to-Wear_Fashion_Show_rbbkgu.jpg')} alt=""/>
                                <div class="mask"></div>
                            </div>
                            <span class="term">Art, design</span>
                            <h3 class="portfolio-item-title">Project management Illustration</h3>
                            <span class="more-button">&hellip;</span>
                        </div>
                    </div>
          </div>
        </TabPanel>
      <TabPanel value={value} index={3} >
item four
      </TabPanel>
    </Box>
  );
}