import React from 'react'
import '../../pages/home.css'
import { useSelector } from 'react-redux'
import { Avatar,Box, useTheme } from '@mui/material'
import { ColorModeContext, tokens } from '../../pages/scenes-dash/theme'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import PinterestIcon from '@mui/icons-material/Pinterest';
import TwitterIcon from '@mui/icons-material/Twitter';
import RedditIcon from '@mui/icons-material/Reddit';
import { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
const avatar = require('../../assets/images/J_Crew_Fall_2016_Ready-to-Wear_Fashion_Show_rbbkgu.jpg')
export default function Home() {
    const {about} = useSelector(state=> state.about)
    const [aboutState, setAbout] = useState({})
    useEffect(()=>{
        setAbout(about)
    },[about])
    const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const colorMode = useContext(ColorModeContext)
  return (
    <Box>
        <section className="home" id="home">
            <div className="container">
                <div className="intro">
                    <div
                        
                    >
                    <Avatar
                        alt="Remy Sharp"
                        src={aboutState?.image?aboutState.image:avatar}
                        sx={{ 
                            width: '200px', 
                            height: '200px',
                            marginBottom:'20px !important' ,
                            margin:'auto',
                            
                            borderRadius:'50%',
                            backgroundPosition:'cover'
                        }}
                    />
                    </div>
                    
                    <h1 style={{
                        color:theme.palette.mode === 'dark'?'white':'black'
                    }}>{aboutState?.user?.firstname}{' '}{aboutState?.user?.lastname}</h1>
                    <span>I'm a <span className="info-text">{aboutState?.about}</span></span>
                    <ul className="social-icons">
                        <li><InstagramIcon/></li>
                        <li><FacebookIcon/></li>
                        <li><PinterestIcon/></li>
                        <li><TwitterIcon/></li>
                        <li><RedditIcon/></li>
                    </ul>
                    <div
                        style={{
                            marginTop:'20px'
                        }}
                    >
                        <a href="#about" className="hire-me btn btn-default">Hire me</a>
                    </div>
                    
                </div>
            </div>
        </section>
    </Box>
    
  )
}
