import { useTheme } from '@emotion/react';
import { Box, IconButton } from '@mui/material';
import React, { useContext,useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ColorModeContext, tokens } from '../scenes-dash/theme';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { CloudRainHeavy } from 'react-bootstrap-icons';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import './search.css'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function MyVerticallyCenteredModal(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const {abouts} = useSelector(state=>state.about)
    const [query, setQuery] = useState('')
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        marginTop:'70px',
        backgroundColor:theme.palette.mode==='dark'?colors.primary[400]:'white'
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4 style={{
        color:'black'
      }}>Search portfolio and store</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className='searchbar'>
        <input 
          onChange={(e)=>setQuery(e.target.value)}
          style={{color:theme.palette.mode === 'dark' && 'black'}} type='text' placeholder='Search' className='searchField'/>
      </div>
      <List sx={{ width: '100%', maxWidth: 360 }}>
      
      
      {
        [...abouts]
        .filter((about)=>
          about.user.firstname.toLowerCase().includes(query)
        ).map((about)=>(
          <a key={about._id} target='_blank'  href={`http://localhost:3000/vendor/${about.info}`}>
            <ListItem  alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Travis Howard" src={about.image} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
                <Typography
                    color='black'
                    fontWeight={800}
                >
                    {about.user.firstname} {about.user.lastname}
                </Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color='grey'
              >
                {about.about}
              </Typography>
             </React.Fragment>
          }
          
        />
      </ListItem>
          </a>
          
        ))
      }
    
    </List>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}


function Search({Element}) {
  const [modalShow, setModalShow] = React.useState(false);
  const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const colorMode = useContext(ColorModeContext)

  return (
    <>
      <Box onClick={() => setModalShow(true)}>
        <Element/>
      </Box>

      <MyVerticallyCenteredModal
        show={modalShow}
        theme={theme}
        colors={colors}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Search