import { useTheme } from '@emotion/react';
import { IconButton } from '@mui/material';
import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ColorModeContext, tokens } from '../scenes-dash/theme';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Login from './components/Login';
import Register from './components/Register';
import './components/style.css';
import { flexibleCompare } from '@fullcalendar/core/internal';
import Prem from './components/Prem';


function MyVerticallyCenteredModal(props) {
  const [showPassword, setShowPassword] = React.useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const colorMode = useContext(ColorModeContext)
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [isLogin, setIsLogin] = useState('Login')
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Modal
      {...props}
     centered
     size='sm'
      
    >
      <Modal.Header
      closeButton>
        <Modal.Title 
        >
          
              Get Premium
           
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
            <Prem />
         
      </Modal.Body>
      <Modal.Footer>
       
         
            <div
            style={{
              display:'flex',
              alignItems:'center',
              justifyContent:'space-between',
              flexDirection:'row'
            }}
           >
             <div>
             <p style={{color:'grey', fontSize:12}}>Enjoy unlimited access to TeetiColab</p>
             </div>
             
           </div>
          
       
      </Modal.Footer>
    </Modal>
  );
}

function Premium({Icon, Btn, Element}) {
  const [modalShow, setModalShow] = useState(false);
  const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const colorMode = useContext(ColorModeContext)
    

  return (
    <>
      {
        Icon && (
          <IconButton color={theme.palette.mode === 'dark' ? 'white':colors.primary[400]} onClick={() => setModalShow(true)}>
        <Icon/>
      </IconButton>
        )
      }
      {
        Btn === true && (
          <button 
            className='btn btn-primary'
            onClick={() => setModalShow(true)}
          >
            Account
        </button>
        )
      }
      {
        Element &&(
          <Box onClick={()=>setModalShow(true)}>
            <Element />
          </Box>
        )
      }

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        
      />
    </>
  );
}

export default Premium