import React from 'react';
import '../style.css'
import { useEffect } from 'react';
import { Add, CancelOutlined, LogoutOutlined, MenuOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Account from '../../pages/modal/Account';
import Tier from '../../pages/modal/Tier';

export default function Header(){
    const navigate = useNavigate();
    const role = localStorage.getItem('role')
    useEffect(()=>{
        const navMenu = document.getElementById("nav-menu"),
        navToggle = document.getElementById("nav-toggle"),
        navClose = document.getElementById("nav-close");
        /*=============== SHOW MENU ===============*/
        /**validate if constant exists */

        if(navToggle){
            navToggle.addEventListener('click',()=>{
                navMenu.classList.add("show-menu")
            })
        }
        /*============== MENU HIDDEN ===============*/
        /**validate if constant exists */
        if(navClose){
            navClose.addEventListener('click', ()=>{
                navMenu.classList.remove("show-menu")
            })
        }

        /*=============== REMOVE MENU MOBILE ===============*/
        const navLinks = document.querySelectorAll(".nav-link")

        function linkAction(){
            const navMenu = document.getElementById("nav-menu")
            // when we click on each nav link, we remove the menu class
            navMenu.classList.remove("show-menu")
            
        }

        navLinks.forEach(n=> n.addEventListener('click', linkAction))

        /*=============== CHANGE BACKGROUND HEADER ===============*/
        function scrollHeader(){
            const header = document.getElementById("header")
            //when the scroll is greater than 80 viewport height, add the class scroll header to the tag header
            if(this.scrollY >= 80) header.classList.add("scroll-header"); else header.classList.remove("scroll-header")
        }
        window.addEventListener("scroll", scrollHeader)
        
    },[])
    return(
        <header id="header" className="headerDash">

        <nav className="navDash containerDash padd-15">
        
          <a href="#" className="nav-logo">
            <h2>Colab</h2>
          </a>  
          <div className="nav-menu" id="nav-menu">

            <ul className="nav-list">
                <li className="nav-item">
                    <a href="#home" className="nav-link active-link">Home</a>
                </li>
                <li className="nav-item">
                    <a href="#about" className="nav-link">About</a>
                </li>
                <li className="nav-item">
                    <a href="#services" className="nav-link">Services</a>
                </li>
                <li className="nav-item">
                    <a href="#experience" className="nav-link">Experience</a>
                </li>
                <li className="nav-item">
                    <a href="#pricing" className="nav-link">Pricing</a>
                </li>
                <li className="nav-item">
                    <a href="#portfolio" className="nav-link">Portfolio</a>
                </li>
                <li className="nav-item">
                    <a href="#testimonials" className="nav-link">Reviews</a>
                </li>
                <li className="nav-item">
                    <a href="#blog" className="nav-link">Blog</a>
                </li>
                <li className="nav-item">
                    <a href="#contact" className="nav-link">Contact</a>
                </li>
            </ul>
            <div className="nav-close" id="nav-close">
                <CancelOutlined className="lni lni-close"/>
            </div>
          </div>
        <div className="nav-btns"
            style={{
                marginBottom:14
            }}
        >
            {
                (role==='admin')&&(
                    <div>
                        <Tier Icon={Add}/>
                    </div>
                )
            }
            <button 
            style={{
                marginRight:6,
                marginLeft:6
            }}
                onClick={()=>{
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    localStorage.removeItem('premium');
                    navigate('/');
                }}
            >
            <LogoutOutlined color='grey' fontSize='40'/>
            </button>
            <div className="nav-toggle" id="nav-toggle">
                <MenuOutlined className="lni lni-grid-alt"/>
            </div>
        </div>
        
        </nav>
    </header>
    );
}