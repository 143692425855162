import React, { useEffect, useState } from 'react';
import './style.css';
import {CircleLoader} from 'react-spinners';
import MainContext from '../../../contexts/MainContext';
import {ArrowCircleLeftOutlined, ArrowCircleRightOutlined, ArrowRightAltRounded } from '@mui/icons-material';
import { addToken } from '../../../redux-store/auth/AuthSlice';
import { useDispatch } from 'react-redux';
import { Box, ChevronBarRight, Eye, EyeSlash } from 'react-bootstrap-icons';

export default function Register({setIsLogin}) {
    const [emailSet, setEmailSet] = useState(false);
    const [flnames, setflnames] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const [error, setError] = useState(null);
    const {checkEmail, loading, emailRes, RegisterUser } = MainContext();
    const [email, setEmail] = useState('');
    const [password, setPsd] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [add, setAdd] = useState(false);
    const [see, setSee] = useState(false);
    const setEmailFunc = async ()=>{
        setLoadingState(true)
        if(email.trim() !== ''){
            const res = await checkEmail(email);
            
            if(res.exists === false){
                setError(null)
                setEmailSet(true)
            }else{
                setError('Email Already in use ')
            }
        }
        setLoadingState(false)
    }
    const setNames = async ()=>{
        if(fname.trim()!== '' && lname.trim()!==''){
            setflnames(true)
        }
    }
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(addToken())
    },[add])
    const RegisterNow = async()=>{
        if(email.trim()===''|| password.trim()===''){
            setError('Fill in all fields');
        }else{
            setError(null);
            const res = await RegisterUser({email, password, firstname:fname, lastname:lname});
            console.log(res)
            if(res.error){
                setError(res.error);
            }else{
                setError(null);
                setIsLogin('Login')
                alert('Account created. Login below')
            }
        }
    }
  return (
    <div className="form">
        {
            flnames === false?(
                <>
                    <div>
                        <div className="namesinput">
                            <label className='labelText'>First Name</label>
                            <input type='text' value={fname}  onChange={(e)=>{setFname(e.target.value)}} placeholder='John' className='input-account'/>
                        </div>
                    </div>
                    <div>
                        <div className="namesinput">
                            <label className='labelText'>Last Name</label>
                            <input type='text' value={lname}  onChange={(e)=>{setLname(e.target.value)}} placeholder='Doe' className='input-account'/>
                        </div>
                    </div>
                </>
            ):(
                <>
                    {
                        emailSet === false?(
                            <div>
                                <button 
                                    onClick={()=>{setflnames(false)}}
                                    style={{
                                        marginBottom:5
                                    }}
                                >
                                    
                                    <h6 style={{display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center', letterSpacing:.4, marginTop:4}}><ArrowCircleLeftOutlined />&nbsp;Back </h6>
                        
                                </button>
                                <div className="namesinput">
                                
                                <label className='labelText'>Email</label>
                                <input type='email' value={email}  onChange={(e)=>{setEmail(e.target.value)}} placeholder='johndoe@gmail.com' className='input-account'/>
                                
                                
                            </div>
                            </div>
                            
                        ):(
                            <div>
                                <button 
                                    onClick={()=>{setEmailSet(false)}}
                                    style={{
                                        marginBottom:5
                                    }}
                                >
                                    
                                    <h6 style={{display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center', letterSpacing:.4, marginTop:4}}><ArrowCircleLeftOutlined />&nbsp;Back </h6>
                        
                                </button>
                               <div className="namesinput" >
                                
                                <label className='labelText'>Password</label>
                                <input type={see?'text':'password'} value={password} onChange={(e)=>{setPsd(e.target.value)}} placeholder='Password' className='input-account'/>
                                <button onClick={()=>setSee(!see)} className='eye'>
                                    {
                                        see?<EyeSlash/>:<Eye />
                                    }
                                </button>
                                
                            </div> 
                            </div>
                            
                        )
                    }
                </>
            )
        }
        {
                        error&&(
                            <small style={{color:'crimson',marginTop:20, marginBottom:20, display:'flex',alignItems:'center', justifyContent:'center', fontWeight:'bold'}}>{error}</small>
                        )
                    }
        
        {
            flnames === false?(
                <button 
                    className='btnLogin'
                    onClick={setNames}
                    style={{
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        borderRadius:10,
                        marginTop:20
                    }}
                >
            {
                loading?(
                    <CircleLoader color='white' size={20}/>
                ):(
                    <h6 style={{display:'flex',
                    alignItems:'center',
                    justifyContent:'center', letterSpacing:.4, marginTop:4}}>Continue &nbsp;<ArrowCircleRightOutlined /></h6>
                )
            }
            
        </button>
            ):(
                <>
                    {
            emailSet === false?(
                <div
                    
                    className='controls'
                >
                    
                    <button 
                                className='btnLogin'
                                onClick={setEmailFunc}
                                style={{
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center',
                                    borderRadius:10,
                                    marginTop:10
                                }}
                            >
                        {
                            loading?(
                                <CircleLoader color='white' size={20}/>
                            ):(
                                <h6 style={{display:'flex',
                                alignItems:'center',
                                justifyContent:'center', letterSpacing:.4, marginTop:4}}>Continue &nbsp;<ArrowCircleRightOutlined /></h6>
                            )
                        }
                        
                    </button>
                </div>
            ):(
                <button 
            className='btnLogin'
            onClick={RegisterNow}
            style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                borderRadius:10,
                marginTop:10
            }}
        >
            {
                loading?(
                    <CircleLoader color='white' size={20}/>
                ):(
                    <h6 style={{display:'flex',
                    alignItems:'center',
                    justifyContent:'center'}}>Finish</h6>
                )
            }
        </button>
            )
        }
                </>
            )
        }
        

    </div>
  )
}
