import React from 'react'
import Header from '../components_prototypes/header'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getAllabouts } from '../redux-store/features/AboutSlice'
import { useTheme } from '@emotion/react'
import { ColorModeContext, tokens } from './scenes-dash/theme'


export default function AppPrototype() {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const colorMode = React.useContext(ColorModeContext)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getAllabouts())
    },[])
  return (
    <>
        <Header/>
    </>
  )
}
