import React,{useState,useEffect, useContext} from 'react'
import TabsPortfolio from '../helpers/Tabs';
import { useTheme } from '@emotion/react';
import { ColorModeContext, tokens } from '../../pages/scenes-dash/theme';

export default function Portfolio() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const colorMode = useContext(ColorModeContext)
  return (
    <section class="portfolio section" id="portfolio">
            <div class="container">
               
                <h2 style={{ color: theme.palette.mode==='dark'?'white':'black'}} class="section-title">Portfolio</h2>
                <div>
                    <TabsPortfolio/>
                </div>
            </div>
        </section>
  )
}
