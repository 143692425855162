import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAllUsers, getUser } from "../redux-store/auth/UserSlice"
import { useParams } from "react-router-dom"
import { useState } from "react"
import { getAbout, getAllabouts } from "../redux-store/features/AboutSlice"
import { BarLoader } from "react-spinners"
import { addToken } from "../redux-store/auth/AuthSlice"
import { Avatar } from "@mui/material"
import { deepOrange } from "@mui/material/colors"
import { ColorModeContext,useMode } from './scenes-dash/theme';
import {CssBaseline, ThemeProvider} from '@mui/material'
import HomeV from './views/home'
import Aos from 'aos/dist/aos'
import './style.css'

function Vendor({}){
    const dispatch = useDispatch()
    const id = useParams()
    const [userid,setId] = useState(id?.userId)
    const {token} = useSelector(state=>state.auth)
    const [isDark,setIsDark] = useState(true)
    const {about} = useSelector(state=>state.about)
    const [theme, colorMode] = useMode()
    Aos.init({
      duration:1800,
      offset:0
    })
    useEffect(()=>{
        dispatch(getUser())
    },[])
    useEffect(()=>{
        dispatch(getAbout(userid))
        dispatch(addToken())
        dispatch(getAllabouts())
        dispatch(getAllUsers())
    },[token])
    useEffect(()=>{
        if(isDark){
          document.body.classList.remove('dark')
          localStorage.setItem('isDark',isDark)
        }else{
          document.body.classList.add('dark')
          localStorage.setItem('isDark',isDark)
        }
      })
    const {loading} = useSelector(state=>state.about)
    return(
        <div 
          
        >
        
                <>
                <ColorModeContext.Provider value={colorMode}>
                  <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <div className='app'>
                      <main className='content'>
                            <HomeV/>
                            </main>
                    </div>
                  </ThemeProvider>
                  
                </ColorModeContext.Provider>
        </>
            
        
        </div>
    )
}
export default Vendor