import { useTheme } from '@emotion/react';
import React from 'react'
import { ColorModeContext, tokens } from '../../pages/scenes-dash/theme';
import { useContext } from 'react';

export default function Services() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const colorMode = useContext(ColorModeContext)
  return (
    <section className="services " id="services">
            <div className="container">
                
                <h2 style={{
                        color:theme.palette.mode === 'dark'?'white':'black'
                    }} className="section-title padd-15">Services</h2>
                <div className="row">
                   
                    <div className="service-item padd-15">
                        <div className="service-item-inner" style={{background: "rgb(108, 108, 229)"}}>
                            <h3>UI/UX design</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi.</p>
                        </div>
                    </div>
                    
                    <div className="service-item padd-15">
                        <div className="service-item-inner" style={{background: "rgb(249, 215, 76)"}}>
                            <h3>Full stack web app design</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi.</p>
                        </div>
                    </div>
                   
                    <div className="service-item padd-15">
                        <div className="service-item-inner" style={{background: "rgb(38,135,133)"}}>
                            <h3>Data management</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi.</p>
                        </div>
                    </div>
                </div>
                <div className="text padd-15">
                   <p>Looking for a custom job? <a href="#">Click here</a> to join community! 👋</p> 
                </div>
            </div>
        </section>
  )
}
