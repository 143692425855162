import { useTheme } from '@emotion/react';
import React, { useContext } from 'react'
import { ColorModeContext, tokens } from '../../pages/scenes-dash/theme';

export default function Qualifications() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const colorMode = useContext(ColorModeContext)
  return (
    <section class="section" id="experience">
            <div class="container">
            
                <h2 style={{color:theme.palette.mode === 'dark' ? 'white': 'black' }} class="section-title padd-15">Experience</h2>
                <div class="row">
                
                    <div class="education padd-15">
                        <div class="row">
                            <div  class="timeline-box">
                                <div style={{backgroundColor:theme.palette.mode === 'dark' ? colors.primary[400]: 'white' }} class="timeline">
                                   
                                    <div class="timeline-item">
                                        <i class="lni lni-graduation timeline-icon"></i>
                                        <h3 class="timeline-date">
                                            <i class="lni lni-calendar"></i> 2019 - present
                                        </h3>
                                        <h3 style={{color:theme.palette.mode === 'dark' ? 'white': 'black' }} class="timeline-title">Bachelor's degree</h3>
                                        <p style={{color:'grey' }} class="timeline-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                                    </div>
                                   
                                    <div class="timeline-item">
                                        <i class="lni lni-graduation timeline-icon"></i>
                                        <h3 class="timeline-date">
                                            <i class="lni lni-calendar"></i> 2015 - 2018
                                        </h3>
                                        <h3 style={{color:theme.palette.mode === 'dark' ? 'white': 'black' }} class="timeline-title">KCSE</h3>
                                        <p style={{color:'grey' }} class="timeline-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                                    </div>
                             
                                    <div class="timeline-item">
                                        <i class="lni lni-graduation timeline-icon"></i>
                                        <h3 class="timeline-date">
                                            <i class="lni lni-calendar"></i> 2006 - 2014
                                        </h3>
                                        <h3 style={{color:theme.palette.mode === 'dark' ? 'white': 'black' }} class="timeline-title">KCPE</h3>
                                        <p style={{color:'grey' }} class="timeline-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div class="experience padd-15" style={{marginTop:'10px'}}>
                        <div class="row">
                            <div  class="timeline-box">
                                <div style={{backgroundColor:theme.palette.mode === 'dark' ? colors.primary[400]: 'white' }} class="timeline">
                                    <div class="timeline-item">
                                        <i class="lni lni-code timeline-icon"></i>
                                        <h3 class="timeline-date">
                                            <i class="lni lni-calendar"></i> 2022 - present
                                        </h3>
                                        <h3 style={{color:theme.palette.mode === 'dark' ? 'white': 'black' }} class="timeline-title">Eccomerce web app design</h3>
                                        <p style={{color:'grey'}} class="timeline-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                                    </div>
                                    <div class="timeline-item">
                                        <i class="lni lni-code timeline-icon"></i>
                                        <h3 class="timeline-date">
                                            <i class="lni lni-calendar"></i> 2022 <small>april</small> - 2022 <small>july</small>
                                        </h3>
                                        <h3 style={{color:theme.palette.mode === 'dark' ? 'white': 'black' }} class="timeline-title">Intern at DTB Bank</h3>
                                        <p style={{color:'grey' }} class="timeline-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}
