import React, { useEffect, useState } from 'react';
import './style.css';
import {CircleLoader} from 'react-spinners';
import MainContext from '../../../contexts/MainContext';
import {ArrowCircleLeftOutlined, ArrowCircleRightOutlined, ArrowRightAltRounded } from '@mui/icons-material';
import { addToken } from '../../../redux-store/auth/AuthSlice';
import { useDispatch } from 'react-redux';
import { Box, ChevronBarRight, Eye, EyeSlash } from 'react-bootstrap-icons';

export default function Login() {
    const [emailSet, setEmailSet] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const [error, setError] = useState(null);
    const {checkEmail, loading, emailRes, LoginUser, verifyPremium } = MainContext();
    const [email, setEmail] = useState('');
    const [password, setPsd] = useState('');
    const [add, setAdd] = useState(false);
    const [see, setSee] = useState(false);
    const setEmailFunc = async ()=>{
        setLoadingState(true)
        if(email !== ''){
            const res = await checkEmail(email);
            
            if(res.exists === true){
                setError(null)
                setEmailSet(true)
            }else{
                setError('Create An Account To Login ')
            }
        }
        setLoadingState(false)
    }
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(addToken())
    },[add])
    const LoginNow = async()=>{
        if(email.trim()===''|| password.trim()===''){
            setError('Fill in all fields');
        }else{
            setError(null);
            const res = await LoginUser({email, password});
            console.log(res)
            if(res.error){
                setError(res.error);
            }else{
                setError(null);
                localStorage.setItem('token', res.user.token);
                localStorage.setItem('user', JSON.stringify(res));
                localStorage.setItem('role', res.user.role);
                await verifyPremium()
                setAdd(true)
            }
        }
    }
  return (
    <div className="form">
        {
            emailSet === false?(
                <div>
                    <div className="namesinput">
                        <label className='labelText'>Email</label>      
                        <input type='email' value={email}  onChange={(e)=>{setEmail(e.target.value)}} placeholder='johndoe@gmail.com' className='input-account'/>
                        </div>
                    </div>
            ):(
                <div>
                                <button 
                                    onClick={()=>{setEmailSet(false)}}
                                    style={{
                                        marginBottom:5
                                    }}
                                >
                                    
                                    <h6 style={{display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center', letterSpacing:.4, marginTop:4}}><ArrowCircleLeftOutlined />&nbsp;Back </h6>
                        
                                </button>
                               <div className="namesinput" >
                                
                                <label className='labelText'>Password</label>
                                <input type={see?'text':'password'} value={password} onChange={(e)=>{setPsd(e.target.value)}} placeholder='Password' className='input-account'/>
                                <button onClick={()=>setSee(!see)} className='eye'>
                                    {
                                        see?<EyeSlash/>:<Eye />
                                    }
                                </button>
                                
                            </div> 
                            </div>
            )
        }
        {
                        error&&(
                            <small style={{color:'crimson',marginTop:20, display:'flex',alignItems:'center', justifyContent:'center', fontWeight:'bold'}}>{error}</small>
                        )
                    }
        
        {
            emailSet === false?(
                <button 
                    className='btnLogin'
                    onClick={setEmailFunc}
                    style={{
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        borderRadius:10,
                        marginTop:40
                    }}
                >
            {
                loading?(
                    <CircleLoader color='white' size={20}/>
                ):(
                    <h6 style={{display:'flex',
                    alignItems:'center',
                    justifyContent:'center', letterSpacing:.4, marginTop:4}}>Continue &nbsp;<ArrowCircleRightOutlined /></h6>
                )
            }
            
        </button>
            ):(
                <button 
            className='btnLogin'
            onClick={LoginNow}
            style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                borderRadius:10,
                marginTop:40
            }}
        >
            {
                loading?(
                    <CircleLoader color='white' size={20}/>
                ):(
                    <h6 style={{display:'flex',
                    alignItems:'center',
                    justifyContent:'center'}}>Login</h6>
                )
            }
        </button>
            )
        }
        

    </div>
  )
}
