import React from 'react';
import { useState } from 'react';
import { url } from '../helpers';

export default function MainContext() {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [emailRes, setEmailOutput] = useState(null)
    const checkEmail = async (email)=>{
      setLoading(true);
      const res = await fetch(`${url}users/check/${email}`,{
          method:'GET',
          headers:{
              "Content-Type":"application/json",
          }
      });
      const result = await res.json();
      setLoading(false);
      return result;
    } 
    const LoginUser = async ({email, password})=>{
      setLoading(true)
      const res = await fetch(`${url}users/login`,{
        method:'POST',
        headers:{
          "Content-Type":"application/json"
        },
        body:JSON.stringify({email, password})
      });
      const result = await res.json();
      setLoading(false);
      return result;
    }
    const RegisterUser = async ({email, password, firstname, lastname})=>{
      setLoading(true)
      const res = await fetch(`${url}users/register`,{
        method:'POST',
        headers:{
          "Content-Type":"application/json"
        },
        body:JSON.stringify({email, password, firstname, lastname})
      });
      const result = await res.json();
      setLoading(false);
      return result;
    } 
    const verifyPremium = async ()=>{
      setLoading(true);
      const res = await fetch(`${url}premium/verify`,{
        method:'GET',
        headers:{
          "Content-Type":"application/json",
          "Authorization":"Bearer "+await localStorage.getItem('token')
        },
      });
      const result = await res.json();
      setLoading(false);
      localStorage.setItem('premium', result.premium);
      return result;
    }
    const getTiers = async ()=>{
      setLoading(true);
      const res = await fetch(`${url}tiers`,{
        method:'GET',
        headers:{
          "Content-Type":"application/json"
        }
      });
      const result = await res.json();
      setLoading(false);
      return result;
    }
    const getTier = async (id)=>{
      setLoading(true);
      const res = await fetch(`${url}tiers/${id}`,{
        method:'GET',
        headers:{
          "Content-Type":"application/json"
        }
      });
      const result = await res.json();
      setLoading(false);
      return result;
    }
    const addTier = async ({name, amount})=>{
      setLoading(true);
      const res = await fetch(`${url}tiers/`,{
        method:'POST',
        headers:{
          "Content-Type":"application/json",
          "Authorization":"Bearer "+localStorage.getItem('token')
        },
        body:JSON.stringify({name,amount})
      });
      const result = await res.json();
      setLoading(false);
      return result;
    }
  return ({
    checkEmail,
    loading,
    error,
    emailRes,
    LoginUser,
    RegisterUser,
    verifyPremium,
    getTiers,
    getTier,
    addTier
  })
}
