import React,{useState} from "react";
import {Menu, MenuItem, ProSidebar} from 'react-pro-sidebar'
import 'react-pro-sidebar/dist/css/styles.css';
import { Avatar, Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../theme";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import PieChartOutlinedIcon from '@mui/icons-material/PieChartOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { useSelector } from "react-redux";

const avatar = require('../../../assets/images/J_Crew_Fall_2016_Ready-to-Wear_Fashion_Show_rbbkgu.jpg')

const SideBar = () =>{
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [selected, setSelected] = useState("Dashboard")
    const {about} = useSelector(state=>state.about)
    return(
        <Box
            sx={{
                "& .pro-sidebar-inner":{
                    background: `${theme.palette.mode==='dark'?colors.primary[400]:colors.primary[900]} !important`
                },
                "& .pro-icon-wrapper":{
                    backgroundColor: "transparent !important"
                },
                "& .pro-inner-item":{
                    padding: "5px 35px 5px 20px !important"
                },
                "& .pro-inner-item:hover":{
                    color: "#868dfb !important"
                },
                "& .pro-menu-item.active":{
                    color: "#6870fa !important"
                }
            }}
            
            
        >
            <ProSidebar  collapsed={isCollapsed}>
                <Menu
                    iconShape="square"
                >
                    <MenuItem
                        onClick={()=> setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed? <MenuOutlinedIcon/>: undefined}
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100]
                        }}
                    >
                    {
                        !isCollapsed && (
                            <Box
                                display='flex'
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Typography variant="h3" color={colors.grey[100]}>
                                    DashBoard
                                </Typography>
                                <IconButton onClick={()=> setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon/>
                                </IconButton>
                            </Box>
                        )
                    }
                    </MenuItem>
                    {
                        !isCollapsed && (
                            <Box mb="25px" mt={5}>
                                <Box 
                                    display='flex' 
                                    justifyContent='center'
                                    alignItems='center'
                                >
                                    <Avatar 
                                        src={about?.image?about.image:avatar} 
                                        alt='Name'
                                        width='200px'
                                        height='200px'
                                        style={{
                                            cursor:'pointer',
                                            borderRadius:'50%'
                                        }}    
                                    />
                                </Box>
                                <Box textAlign='center'>
                                    <Typography
                                        variant="h2"
                                        color={colors.grey[100]}
                                        fontWeight='bold'
                                        sx={{
                                            m: "10px 0 0 0"
                                        }}
                                    >Ed Roh</Typography>
                                    <Typography
                                        variant="h5"
                                        color={colors.greenAccent[500]}
                                    >Vp Fancy Admin</Typography>
                                </Box>
                            </Box>
                        )
                    } 
                </Menu>
            </ProSidebar>
            
        </Box>        
    )
}

export default SideBar