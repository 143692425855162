import React, { useEffect, useState } from 'react';
import './style.css';
import {CircleLoader} from 'react-spinners';
import MainContext from '../../../contexts/MainContext';
import {ArrowCircleLeftOutlined, ArrowCircleRightOutlined, ArrowRightAltRounded } from '@mui/icons-material';
import { addToken } from '../../../redux-store/auth/AuthSlice';
import { useDispatch } from 'react-redux';
import { Box, ChevronBarRight, Eye, EyeSlash } from 'react-bootstrap-icons';

export default function CreateStore() {
    const [emailSet, setEmailSet] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const [error, setError] = useState(null);
    const {checkEmail, loading, emailRes, verifyPremium } = MainContext();
    const [email, setEmail] = useState('');
    const [password, setPsd] = useState('');
    const [add, setAdd] = useState(false);
    const [see, setSee] = useState(false);
   
    const dispatch = useDispatch();

    const verify = async ()=>{
        const res = await verifyPremium()
    }
    useEffect( ()=>{
        verify();
    },[])
    
  return (
    <div className="form">
       
                <div>
                    <div className="namesinput">
                        <label className='labelText'>Email</label>      
                        <input type='email' value={email}  onChange={(e)=>{setEmail(e.target.value)}} placeholder='johndoe@gmail.com' className='input-account'/>
                        </div>
                    </div>
         
        {
                        error&&(
                            <small style={{color:'crimson',marginTop:20, display:'flex',alignItems:'center', justifyContent:'center', fontWeight:'bold'}}>{error}</small>
                        )
                    }
        
                <button 
                    className='btnLogin'
                    onClick={verify}
                    style={{
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        borderRadius:10,
                        marginTop:40
                    }}
                >
            {
                loading?(
                    <CircleLoader color='white' size={20}/>
                ):(
                    <h6 style={{display:'flex',
                    alignItems:'center',
                    justifyContent:'center', letterSpacing:.4, marginTop:4}}>Continue &nbsp;<ArrowCircleRightOutlined /></h6>
                )
            }
            
        </button>
         
        

    </div>
  )
}
