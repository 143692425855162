import React from 'react'
import './action.css'
import Account from '../../pages/modal/Account'
import Premium from '../../pages/modal/Premium'
import { useSelector } from 'react-redux'
import Store from '../../pages/modal/Store'

function ElemGetStarted3(){
  return(
    <div className="home-get-started2 template-button">
                <span className="home-text030">Get Premium</span>
              </div>
  )
}
function ElemGetStarted(){
  return(
    <div className="home-get-started2 template-button">
                <span className="home-text030">Create Store</span>
              </div>
  )
}

export default function Action() {
  const {premium} = useSelector(state=>state.auth)
  return (
    <section className="home-action-bar">
        <div className="home-action">
          <div className="home-heading3">
            <span className="home-text095 title">
              {
                premium==='true'?(
                  <span>Create Store</span>
                ):(
                  <span>Premium</span>
                )
              }
              
              
            </span>
            {
              premium=== 'true'?(
                <span className="home-text099">
              Create an online store today and customize to your needs. Thankyou for joining The Colab community 🙂.
            </span>
              ):(
                <span className="home-text099">
              To Create an online store and enjoy unlimited access to teeticolab buy premium tier.
            </span>
              )
            }
            {
              premium==='true'?(
                <Store Element={ElemGetStarted}/>
              ):(
                <Premium Element={ElemGetStarted3}/>
              )
            }
            
          </div>
          <div className="home-images">
            <img
              alt="image"
              src={require("../../assets/playground_assets/e564eaa3c4fab71792794d666a281742-1200w.png")}
              className="home-image1"
            />
          </div>
        </div>
      </section>
  )
}
