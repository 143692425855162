import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { fetch3 } from "../../helpers";


const initialState = {
    error:null,
    loading:false,
    business:{},
    businesses:[],
    products:[],
    categories:[]

}
export const getAllCategory = createAsyncThunk(
    'getAllCategory',
    async(id)=>{
        const results = await fetch3(`category/${id}`)
        return results
    }
)
export const getAllStores = createAsyncThunk(
    'getAllStores',
    async()=>{
        const results = await fetch3('stores/')
        return results
    }
)
export const getStore = createAsyncThunk(
    'getStore',
    async(id)=>{
        const results = await fetch3(`stores/${id}`)
        return results
    }
)
export const getStoreProducts = createAsyncThunk(
    'getStoreProducts',
    async(id)=>{
        const results = await fetch3(`stores/products/${id}`)
        return results
    }
)

const businessSlice = createSlice({
    name:'business',
    initialState,
    reducers:{

    },
    extraReducers:(builder)=>{
        builder
        .addCase(getAllStores.fulfilled,(state,{payload:{error,businesses}})=>{
            state.loading = false;
            if(error){
                state.businesses = []
                state.error = error
            }else{
                state.error = null;
                state.businesses = businesses
            }
        })
        .addCase(getAllStores.pending,(state,action)=>{
            state.loading = true
        })
        .addCase(getStore.fulfilled,(state,{payload:{error,business}})=>{
            state.loading=false;
            if(error){
                state.business = null
                state.error = error
            }else{
                state.business = business
                state.error = null
            }
        })
        .addCase(getStore.pending,(state,action)=>{
            state.loading = true;
        })
        .addCase(getStoreProducts.fulfilled,(state,{payload:{error,products}})=>{
            state.loading=false;
            if(error){
                state.products = null
                state.error = error
            }else{
                state.products = products
                state.error = null
            }
        })
        .addCase(getStoreProducts.pending,(state,action)=>{
            state.loading = true;
        })
        .addCase(getAllCategory.fulfilled,(state,{payload:{error, categories}})=>{
            state.loading = false;
            if(error){
                state.categories = null
                state.error = error
            }else{
                state.categories = categories
                state.error = error
            }
        })
        .addCase(getAllCategory.pending,(state,action)=>{
            state.loading = false
        })
    }
})

export const {} = businessSlice.actions
export default businessSlice.reducer