import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetch3 } from "../../helpers";

const initialState={
    users:[],
    user:{},
    loadingUser:false,
    errorUser:''
};

export const getUser = createAsyncThunk(
    'getUser',
    async (id)=>{
        const result = await fetch3(`users/${id}`)
        return result
    }
)
export const getAllUsers = createAsyncThunk(
    'getAllUsers',
    async ()=>{
        const result = await fetch3(`users/`)
        return result
    }
)

const userSlice = createSlice({
    name:'user',
    initialState,
    reducers:{
        userid :(state,action)=>{

        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getUser.fulfilled,(state,{payload:{error,user}})=>{
            state.loadingUser = false
            if(error){
                state.errorUser = error
                state.user = null
            }else{
                state.errorUser = null
                state.user = user
            }
        })
        .addCase(getUser.pending,(state,action)=>{
            state.loadingUser = true
        })
        .addCase(getAllUsers.fulfilled, (state,{payload:{error,users}})=>{
            state.loadingUser = false
            if(error){
                state.errorUser = error
                state.users = []
            }else{
                state.errorUser = null
                state.users = users
            }
        })
        .addCase(getAllUsers.pending,(state,action)=>{
            state.loadingUser = true
        })
    }
})
export const {userid} = userSlice.actions
export default userSlice.reducer