import React,{memo, useState,useEffect} from 'react'
import {About, Header, HomeView, Qualifications, Services, Skills} from '../components'
import '../App.css'
import { Avatar, createStyles, makeStyles } from '@mui/material'
import { deepOrange } from '@mui/material/colors'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { BarLoader, PuffLoader } from 'react-spinners'
import { getUser } from '../redux-store/auth/UserSlice'
import { getAbout, getAllabouts } from '../redux-store/features/AboutSlice'
import Footer from '../components/footer/Footer'
import { ColorModeContext,useMode } from './scenes-dash/theme';
import {CssBaseline, ThemeProvider} from '@mui/material'
import "./home.css"
function Home() {
  const dispatch = useDispatch()
  const error_404 = require('../assets/images/404.png')
  const [isDark, setIsDark] = useState(localStorage.getItem('isDark'))
    const id = useParams()
    const {loading,about} = useSelector(state=>state.about)
    const [userid,setId] = useState(id?.userId)
    const {user,errorUser} = useSelector(state=>state.user)
    const [userState,setUser] = useState({})
    useEffect(()=>{
      dispatch(getUser(userid))
      setIsDark(localStorage.getItem('isDark'))
    },[])
  useEffect(()=>{
    setUser(about)
  },[about])
  
  useEffect(()=>{
      dispatch(getAbout(userid))
      dispatch(getAllabouts())
  },[])
  const [theme, colorMode] = useMode()
 
  return (

    <>
    {
      userState?(
        <>
        {
          loading?(
            <div 
              className='loader_div'
              style={{
                flexDirection:'column',
                
              }}
            >   
            <div style={{fontWeight:'800'}}>
              <span style={{color:isDark?'white':'black'}}>Teeti</span><span style={{color:'#0077b5'}}>Colab</span>
            </div>
            <div 
              style={{width:'20px'}}
            >
            <BarLoader color='#0077b5'width='50%' style={{color:'#ffd9d9',width:'100%'}}/>
            </div>
            </div>
          ):(
            <>
            <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <div className='app'>
          <main className='content'>
   
          <Header/>
          
          </main>
        </div>
      </ThemeProvider>
      
    </ColorModeContext.Provider>
            
              
            </>
          )
        }
        </>
      ):(
       <>
        <div
          style={{
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            marginTop:160,
            flexDirection:'column'
          }}
        >
          <img src={error_404} style={{
            width:180,
            height:180
          }}/>
          <p>{errorUser}</p>
        </div>
       </>
      )
    }
      

      
    </>
  )
}



export default memo(Home)