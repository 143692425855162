import React from "react";
import {Box, IconButton, Typography, useTheme} from '@mui/material'
import { ColorModeContext, tokens } from "../theme";
import InputBase from "@mui/material/InputBase";
import TextField from '@mui/material/TextField'
import  LightModeOutlinedIcon  from "@mui/icons-material/LightModeOutlined";
import  DarkModeOutlinedIcon  from "@mui/icons-material/DarkModeOutlined";
import  NotificationsOutlinedIcon  from "@mui/icons-material/NotificationsOutlined";
import  SettingsOutlinedIcon  from "@mui/icons-material/SettingsOutlined";
import  PersonOutlinedIcon  from "@mui/icons-material/PersonOutlined";
import  SearchIcon  from "@mui/icons-material/Search";
import { useContext } from "react";
import styled from "@emotion/styled";
import Account from "../../modal/Account";
import Search from "../../modal/Search";

function SearchElement() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const colorMode = useContext(ColorModeContext)
    return(
      <Box
                      display='flex'
                      backgroundColor={theme.palette.mode ==='dark'?colors.primary[400]:colors.primary[900]}
                      borderRadius='3px'
                  >
                      <Typography paddingLeft='20px' paddingTop="7px" variant="span"
                        color={theme.palette.mode ==='dark'?"lightgrey":'black'}
                        fontSize='15px'
                      >
                        Search
                      </Typography>
                     <IconButton  sx={{p:1}}>
                      <SearchIcon/>
                     </IconButton>
                  
                  </Box>
    )
  }
const TopBar = () =>{
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const colorMode = useContext(ColorModeContext)
    return (
        <Box display="flex" marginTop='10px' bgcolor={colors.primary} justifyContent="space-between" p={2}>
            
                <Search Element={SearchElement}/>
             <Box
                display='flex'
             >
               
                <IconButton
                    sx={{
                        marginLeft:'25px'
                    }}
                    color={theme.palette.mode === 'dark' ? 'white':colors.primary[400]}
                    onClick={colorMode.toggleColorMode}
                >
                    {theme.palette.mode === 'dark' ? (
                        <DarkModeOutlinedIcon color='white'/>
                    ):(
                        <LightModeOutlinedIcon color='white'/>
                    )}
                    
                </IconButton>
                <Account  Icon={PersonOutlinedIcon}/>
               
              
                
             </Box>
        </Box>
    )
}

export default TopBar